import * as React from 'react';
import { useEffect, useRef } from 'react';

import { RouteComponentProps } from '@reach/router';
import Button from '@rotaryintl/harris-button';

import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';

import { getReportsPath } from '@use-cases/clubs';
import { useNotifications } from '@use-cases/notifications';

import { useFetchBIReportAccess } from '@repositories/reports';

import { localizedNavigate } from '@utils/localized-navigate';
import { getQueryParams } from '@utils/query-params';
import { closeChannel, getChannel } from '@utils/sendMessage';

import { useTranslation } from '@external/react-i18next';

interface BackButtonProps {
  t: (key: string, defaultValue?: string) => string;
  onBackClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ t, onBackClick }) => (
  <Button
    clickHandler={onBackClick}
    icon="fas-angle-left"
    size="md"
    variant="text"
    className="mt-6"
  >
    {t('back-link-text', 'Back')}
  </Button>
);

interface Props extends RouteComponentProps {
  oktaId: string;
  dataSelector: string;
}

const ClubAndDistrictReports: React.FC<Props> = ({ oktaId, dataSelector }) => {
  const { t } = useTranslation();
  const { clubId, districtId } = getQueryParams();
  const { data, loading } = useFetchBIReportAccess(
    oktaId,
    clubId as string | null,
    districtId as string | null
  );
  const { addError } = useNotifications();
  const addErrorRef = useRef(addError);

  useEffect(() => {
    const biReportChannel = getChannel('bi-report');
    biReportChannel.onmessage = event => {
      if (event?.data) {
        const errorMessage = t(
          'club-and-district-reports.error-message',
          'We are sorry we are unable to download your BI report. Please check again later.'
        );
        addErrorRef.current(errorMessage, { id: 'bireport' });
      }
    };
    return () => {
      closeChannel('bi-report');
    };
  }, [t]);

  if (loading || !data) {
    return <Loading />;
  }

  // For club tabs
  const storedActiveTabData = window.localStorage.getItem('activeTabValue');
  const activeTabData = storedActiveTabData
    ? JSON.parse(storedActiveTabData)
    : [];
  const navigatedFromClubReportsTab =
    activeTabData[0]?.value?.includes('/reports') && dataSelector === 'club';

  const navigatedFromClubRosterTab =
    activeTabData[0]?.value?.includes('/roster') && dataSelector === 'club';

  // For district tabs
  const storedActiveTabDataDistrict = window.localStorage.getItem(
    'activeDistrictTabValue'
  );
  const activeTabDataDistrict = storedActiveTabDataDistrict
    ? JSON.parse(storedActiveTabDataDistrict)
    : [];
  const navigatedFromDistrictReportsTab =
    activeTabDataDistrict[0]?.value?.includes('/reports') &&
    dataSelector === 'district';

  const handleBackNavigation = () => {
    let reportsPath = getReportsPath();
    if (navigatedFromClubReportsTab || navigatedFromClubRosterTab) {
      reportsPath = activeTabData[0]?.value;
    } else if (navigatedFromDistrictReportsTab) {
      reportsPath = activeTabDataDistrict[0]?.value;
    }
    localizedNavigate(reportsPath);
  };

  if (!data.access) {
    return (
      <OneColumn className="mb-20">
        <BackButton t={t} onBackClick={handleBackNavigation} />
        <AccessDenied />
      </OneColumn>
    );
  }

  return (
    <OneColumn className="mb-20">
      <BackButton t={t} onBackClick={handleBackNavigation} />
      <h1 className="font-semibold text-xl-38 mobile-m:text-xl-48 tablet:text-xl-58 tablet:p-0 m-0 my-6 tablet:mb-8 tablet:leading-xl-58">
        {t(
          'club-and-district-reports.title',
          'Membership And Foundation Reports'
        )}
      </h1>
      <div id="ClubAndDistrictDownloadReports">
        <span>
          {t(
            'club-and-district-reports.download-update-text',
            'We are downloading membership and foundation reports for you and opening it in new tab.'
          )}
        </span>
      </div>
    </OneColumn>
  );
};

export default ClubAndDistrictReports;
